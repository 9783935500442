import "animate.css"
import "./styles/global.scss"
import "./App.css"
import {Pool} from "./components/Pool";
import {Header} from "./components/Header";

function App() {

  const pools = [
    {
      days: '14',
      percent: '1.25%',
      limits: '100-800'
    },
    {
      days: '30',
      percent: '1.73%',
      limits: '500-1500'
    },
    {
      days: '60',
      percent: '1.95%',
      limits: '800-5000'
    },
    {
      days: '90',
      percent: '2.22%',
      limits: '5000-10.000'
    },
    {
      days: '160',
      percent: '3.5%',
      limits: 'from 10.000',
      comingSoon: true
    },
  ]
  return (
    <main>
      <Header/>
      <div className='main-content-wrapper'>
        <div>
          <div className="animate__animated animate__bounce attention">
            <p>
              We are excited to share that Quonta Finance has entered its Beta phase. Your partnership has been
              invaluable in shaping our products, and we thank you for your ongoing support. Support: <a
              href="mailto:support@quonta.finance">support@quonta.finance</a>
            </p>
          </div>
        </div>
        <div className='main-content'>
        {pools.map((i, index) => (
            <Pool key={index} days={i.days} percents={i.percent} comingSoon={i.comingSoon} limits={i.limits}/>
          ))}
        </div>
      </div>
    </main>
  );
}

export default App;
